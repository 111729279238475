import React from 'react';
import './Header.scss';
import HeaderLogo from './HeaderLogo';
import Ball from './Ball';

const Header = () => {
  return (
    <div className="header">
      <HeaderLogo/>
      <div className="header-balls">
        {[...Array(12)].map((_, index) => (
          <div className='header-ball'>
            <Ball
              key={index}
              index={index}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Header;