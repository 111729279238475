import React, { Key, useEffect, useState } from 'react';
import './ValidateVolunteer.scss';
import { fetchWish, grantWish, validateVolunteer, validateWish } from '../api/api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import WishItem from './WishItem';
import { Wish } from '../models/wish';
import Popup from './Popup';
import Footer from './Footer';
import Header from './Header';
import SimpleBar from 'simplebar-react';

function ValidateVolunteer({ role }: {role: string}) {
	const [wish, setWish] = useState<Wish>();
	const [showPopup, setShowPopup] = useState(false);
	const [popupContent, setPopupContent] = useState('');
	const { wishId } = useParams();
	const navigate = useNavigate();

	const fetchData = async () => {
		try {
			if (wishId) {
				const { data } = await fetchWish(wishId);
				console.log(data);
				setWish(data);
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const submitValidateVolunteer = async (isApproved: boolean, comment: string) => {
		try {
			if (wishId) {
				const { data } = await validateVolunteer(wishId, isApproved, comment);
				console.log(data);
				setPopupContent(isApproved ? 'Одобрено' : 'Отказано');
				setShowPopup(true);
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const onClose = () => {
		setShowPopup(false);
		navigate(`/?token=${localStorage.getItem('token')}`);
	}

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		console.log("ROLE", role);
		if (role !== 'admin') {
		  // If the user has the role of 'volunteer', navigate them to a different route
		  navigate(`/?token=${localStorage.getItem('token')}`);
		}
	}, [role]);

	return (
		<>
			<Header/>
			<SimpleBar className="volunteerValidation">
				{
					wish ? (
						<div>
							{
								wish ? <WishItem wish={wish} ></WishItem> : null
							}
							<br/>
							<div className='volunteerValidation-header'>Исполнение</div>
							<b>Имя исполнителя:</b> {wish?.volunteer.name} <br/>
							<b>Email:</b> {wish?.volunteer.email} <br/>
							<b>Место доставки:</b> {wish?.volunteer.delivery} <br/>
							<br/>
							<div className="wishValidation-footer">
								{(!wish?.checked || !wish?.approved) ? <button onClick={() =>
									submitValidateVolunteer(true, "")}>Одобрить</button> : ''}
								{(!wish?.checked || wish?.approved) ? <button onClick={() =>
									submitValidateVolunteer(false, "cancelled")}>Отказать</button> : ''}
							</div>
						</div>
					) : <div>not found</div>
				}
			</SimpleBar>
			<Footer/>
			{showPopup && (
				<Popup content={popupContent} onClose={onClose} />
			)}
		</>
	);
}

export default ValidateVolunteer;
