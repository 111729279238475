import React, { Key, useEffect, useState } from 'react';
import './WishDetails.scss';
import { fetchWish, grantWish } from '../api/api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Wish } from '../models/wish';
import WishItem from './WishItem';
import Popup from './Popup';
import Footer from './Footer';
import Header from './Header';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

const defaultErrors = {
	email: false,
	volunteerName: false,
	volunteerPhone: false,
	delivery: false,
};

function WishDetails({role}: {role: string}) {
	const [isChecked, setIsChecked] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const [popupContent, setPopupContent] = useState('');
	const [wish, setWish] = useState<Wish>();
	const [email, setEmail] = useState('');
	const [volunteerPhone, setVolunteerPhone] = useState('');
	const [volunteerName, setVolunteerName] = useState('');
	const [delivery, setDelivery] = useState('');
	const [addresses, setAddresses] = useState([]);
	const [errors, setErrors] = useState(defaultErrors);
	const [isDisabled, setIsDisabled] = useState(false);
	const { wishId } = useParams();
	const navigate = useNavigate();

	const onClose = () => {
		setShowPopup(false);
		navigate(`/?token=${localStorage.getItem('token')}`);
	}

	const validateWish = () => {
		setErrors(defaultErrors);
		const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
		if (!isEmail) {
			return displayError('email', 'Неверный формат email');
		}
		const isTel = /^(?:\+7|8)\d{10}$/.test(volunteerPhone);
		if (!isTel) {
			return displayError('contact', 'Неверный формат номера телефона');
		}
	};

	const displayError = (field: any, msg: string | undefined) => {
		setErrors({...errors,  [field]: msg });
		throw new Error(msg);
	}

	const fetchData = async () => {
		try {
			if (wishId) {
				const { data } = await fetchWish(wishId);
				console.log(data);
				setWish(data);
				setAddresses(data?.addresses || []);
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const submitGrantWish = async (e: { preventDefault: () => void; }) => {
		e.preventDefault();
		if (!isChecked) {
			alert("Необходимо согласиться с условиями обработки персональных данных.");
			return;
		}
		try {
			validateWish();
			if (isDisabled) {
				return;
			}
			if (wishId) {
				setIsDisabled(true);
				const { data } = await grantWish(wishId, {
					name: volunteerName,
					phone: volunteerPhone,
					email,
					delivery
				});
				console.log(data);
				setPopupContent('Заявка на исполнение желания отправлена');
				setShowPopup(true);
				e.preventDefault();
				setIsDisabled(false);
			}
		} catch (error: any) {
			console.error('Error:', error);
			if (error?.response?.data) {
				alert(error.response.data);
			}
			setIsDisabled(false);
		}
	};

	const handleNameChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
		setVolunteerName(e.target.value);
	};

	const handlePhoneChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
		setVolunteerPhone(e.target.value);
		setErrors({...errors, volunteerPhone: false});
		const isTel = /^(?:\+7|8)\d{10}$/.test(e.target.value.toString());
		if (!isTel) {
			return displayError('volunteerPhone', 'Неверный формат номера телефона');
		}
	};

	const handleEmailChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
		setEmail(e.target.value);
		setErrors({...errors, email: false});
		const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value.toString());
		if (!isEmail) {
			return displayError('email', 'Неверный формат email');
		}
	};

	const handleDeliveryChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
		setDelivery(e.target.value);
	};

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsChecked(e.target.checked);
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<Header />
			<SimpleBar className="wishDetails">
				{
					wish ? <WishItem wish={wish} ></WishItem> : null
				}
				<br/>
				{
					wish?.volunteer?.canGrant && ['admin', 'volunteer'].includes(role) ? (
						<form className="wishDetails-form" onSubmit={submitGrantWish}>
							<div className="wishDetails-fields">
								<label className={errors['email'] ? 'hasError' : ''}>
									Email дарителя
									<input value={email} onChange={handleEmailChange} required/>
									<div className="errorMsg">{errors['email']}</div>
								</label>
								<label className={errors['volunteerName'] ? 'hasError' : ''}>
									ФИО дарителя
									<input type="text" value={volunteerName} onChange={handleNameChange} required/>
									<div className="errorMsg">{errors['volunteerName']}</div>
								</label>
								<label className={errors['volunteerPhone'] ? 'hasError' : ''}>
									Мобильный телефон (+7XXXXXXXXXX)
									<input type="text" value={volunteerPhone} onChange={handlePhoneChange} required/>
									<div className="errorMsg">{errors['volunteerPhone']}</div>
								</label>
								<label className={errors['delivery'] ? 'hasError' : ''}>
									Место доставки
									<select value={delivery} onChange={handleDeliveryChange}>
										{
											addresses.map(option => (
												<option key={option} value={option}>{option}</option>
											))
										}
									</select>
									<div className="errorMsg">{errors['delivery']}</div>
								</label>
							</div>
							<div className="agreement-section">
								<label htmlFor="agreement">
									<input
										className='agreement-checkbox'
										id="agreement"
										type="checkbox"
										checked={isChecked}
										onChange={handleCheckboxChange}
									></input>
									<span>
										Нажимая на кнопку «Начать заполнение анкеты», я &nbsp;
										<a
											className="agreement-link"
											href="/agreement_volunteer.pdf"
											target="_blank"
										>
										даю согласие на обработку персональных данных &nbsp;
										</a>
										в соответствии с законодательством Российской Федерации и &nbsp;
										<a
											className="agreement-link"
											href="/personalDataPolicy.pdf"
											target="_blank"
										>«Политикой обработки персональных данных ПАО «Интер РАО».
										</a>
									</span>
								</label>
							</div>
							<button disabled={isDisabled}>Отправить</button>
						</form>
					) : null
				}
			</SimpleBar>
			<Footer />
			{showPopup && (
				<Popup content={popupContent} onClose={onClose} />
			)}
		</>
	)
}

export default WishDetails;
