import React, { ReactNode } from 'react';
import './Popup.scss';
import { ReactComponent as CrossIcon } from '../assets/icons/cross.svg';
import SimpleBar from 'simplebar-react';

const Popup = ({ header, content, onClose } : {header?: string, content: string | ReactNode, onClose: () => void}) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>
          <CrossIcon/>
        </button>
        <div className="popup-header">
          {header}
        </div>
        <SimpleBar className="popup-body">
          {content}
        </SimpleBar>
          {/* <br/>
          <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at sapien non metus porttitor eleifend. Aliquam ut accumsan enim, ut cursus leo. Duis sagittis luctus risus vel dapibus. Nam velit leo, tempor eu eros sagittis, pharetra aliquet libero. Nam pharetra, enim a pretium posuere, sem erat semper nunc, id aliquam purus lacus sit amet leo. Vestibulum vel purus sed ligula mollis bibendum ut ut metus. 
          <br/>
          <br/>
          Cras ullamcorper aliquet massa vel ultrices. Nam imperdiet ultricies lorem, in venenatis ante venenatis et. In lacinia dignissim arcu, ut porttitor mi ornare id. Morbi nec diam scelerisque, iaculis ipsum ut, efficitur ante. Ut nibh elit, efficitur quis convallis ut, elementum eget risus. Etiam ante risus, vehicula eu tortor eget, tincidunt condimentum tellus. Aenean porttitor ex et quam ultrices, tristique tincidunt magna aliquam. Aenean vestibulum et diam id lobortis. </div> */}
          {/* </div> */}
      </div>
    </div>
  );
};

export default Popup;