import './WishItem.scss';
import { Wish } from '../models/wish';
import React, { useEffect, useState } from 'react';
import { ReactComponent as PDFIcon } from '../assets/icons/pdf.svg';

function WishItem({wish}: {wish: Wish}) {
	const [embeddedPdfUrl, setEmbeddedPdfUrl] = useState('');

	return (
		(wish && wish.details) ? (
			<div className="wish">
				<div className='wish-left'>
					<div className="wish-header">{(wish.details.gift?.length > 50) ? (wish.details.gift.substring(0, 50) + '...') : wish.details.gift }</div>
					<div className="wish-details">
						<div>
							<b>Имя ребёнка:</b> {wish.details.child} <br/>
							<b>Возраст ребёнка:</b> {wish.details.age} <br/>
						{/*	<b>Категория подарка:</b> {wish.details.category} <br />*/}
							<b>Краткое описание подарка:</b> {(wish.details.gift?.length > 50) ? (wish.details.gift.substring(0, 50) + '...') : wish.details.gift } <br/>
							<b>Ссылка на пример подарка:</b> {
								(wish.details.link) ? <a rel="noreferrer" target='_blank' href={wish.details.link}>Ссылка</a> : ''
							}<br/>
							{/*<b>Номер телефона:</b> {wish.details.contact} <br/>
							<b>Место доставки:</b> {wish.details.delivery}*/}
							<b>Статус:</b> {
							(wish.approved) ? <span style={{color: 'green'}}><b>ОДОБРЕНО</b></span> :
								(wish.checked) ? <span style={{color: 'red'}}><b>ОТКЛОНЕНО</b></span>
									: <span style={{color: 'orange'}}><b>НЕ ОДОБРЕНО</b></span>
							}
							{
								(wish?.volunteer?.approved) ?
									<div className='wishList-tag wishList-tag--fulfilled'>Исполнено</div> : null
							}
						</div>
					</div>
				</div>
				<div
					className='wish-photo'
					style={{
						overflow: 'hidden'
					}}
				>
					{
						(wish.details.imageUrl) ? (
							(wish.details.imageUrl.toLowerCase().endsWith('.pdf')) ? (
								<a
									href={`${process.env.REACT_APP_SUBMIT_IP}/uploads/${wish.details.imageUrl}`}
									target="_blank"
									rel="noreferrer"
								>
									<div
										className='wish-pdfIcon'
										
									>
										<PDFIcon/>
									</div>
								</a>
							) : (
								<a 
									href={`${process.env.REACT_APP_SUBMIT_IP}/uploads/${wish.details.imageUrl}`}
									target="_blank"
									rel="noreferrer"
								>
									<img
										src={`${process.env.REACT_APP_SUBMIT_IP}/uploads/${wish.details.imageUrl}`}
										alt={wish.details.gift}
										loading='lazy'
									/>
								</a>
							)
						) : null
					}
				</div>
			</div>
		) : null
	);
}

export default WishItem;
