import React, { Key, useEffect, useState } from 'react';
import './ValidateWish.scss';
import { fetchWish, grantWish, validateWish } from '../api/api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Wish } from '../models/wish';
import WishItem from './WishItem';
import Popup from './Popup';
import Footer from './Footer';
import Header from './Header';
import InputDialog from "./InputDialog";

function ValidateWish({ role }: {role: string}) {
	const [showPopup, setShowPopup] = useState(false);
	const [popupContent, setPopupContent] = useState('');
	const [wish, setWish] = useState<Wish>();
	const [volunteerName, setVolunteerName] = useState('');
	const { wishId } = useParams();
	const navigate = useNavigate();
	const [showCommentDialog, setShowCommentDialog] = useState(false);
	const [commentDialogContent, setCommentDialogContent] = useState('');

	const onClose = () => {
		setShowPopup(false);
		navigate(`/?token=${localStorage.getItem('token')}`);
	}

	const onReject = () => {
		setCommentDialogContent("Подробно поясните автору желания, как скорректировать заявку, чтобы желание стало более осуществимым")
		setShowCommentDialog(true);
	}

	const fetchData = async () => {
		try {
			if (wishId) {
				const { data } = await fetchWish(wishId);
				console.log(data);
				setWish(data);
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const submitValidateWish = async (isApproved: boolean, comment: string) => {
		try {
			if (wishId) {
				const { data } = await validateWish(wishId, isApproved, comment);
				console.log(data);
				setPopupContent(isApproved ? 'Одобрено' : 'Отказано');
				setShowPopup(true);
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		console.log("ROLE", role);
		if (role !== 'admin') {
		  // If the user has the role of 'volunteer', navigate them to a different route
		  navigate(`/?token=${localStorage.getItem('token')}`);
		}
	}, [role]);

	return (
		<>
			<Header/>
			{
				wish ? (
					<>
						<div className="wishValidation">
							{
								wish ? <WishItem wish={wish} ></WishItem> : null
							}
							<div className="wishValidation-footer">
								{(!wish?.checked || !wish?.approved) ? <button onClick={() => submitValidateWish(true, "Одобрено")}>Одобрить</button> : ''}
								{(!wish?.checked || wish?.approved) ? <button onClick={() => onReject()}>Отказать</button> : ''}
							</div>
						</div>
					</>
				) : <div>not found</div>
			}
			<Footer/>
			{showPopup && (
				<Popup content={popupContent} onClose={onClose} />
			)}

			{(showCommentDialog) ? (
				<InputDialog
					header={"Форма подтверждения отказа по заявке"}
					areaHeader={"Укажите причину отказа:"}
					areaValue={ commentDialogContent }
					onConfirm={(value) => {
						setShowCommentDialog(false)
						submitValidateWish(false, value)
					}}
					onClose={() => {
						setCommentDialogContent("")
						setShowCommentDialog(false)
					}}/>
			) : null
			}
		</>
	);
}

export default ValidateWish;
