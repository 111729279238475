import axios from 'axios';
import { Volunteer, WishDetails } from '../models/wish';

const baseURL = process.env.REACT_APP_SUBMIT_IP;

console.log('axios');
axios.interceptors.request.use((config) => {
	// @ts-ignore
	config.headers["Authorization"] = `Bearer ${localStorage.getItem('token')}`

	// config.headers = {
	// 'Authorization': `Bearer ${localStorage.getItem('token')}`
	// };
	return config;
}, (error) => {
	return Promise.reject(error);
});

axios.interceptors.response.use(
	response => {
	const modifiedResponse = {
	...response,
	data: response.data // Process the data as needed
	};
	return modifiedResponse;
	},
	error => {
	// Handle error
	return Promise.reject(error);
	}
);

const fetchAllWishes = () => {
	return axios.get(`${baseURL}/all-wishes`);
};

const fetchUserWish = () => {
	return axios.get(`${baseURL}/user-wish`);
};

const fetchWish = (wishId: string) => {
	return axios.get(`${baseURL}/wish-details/${wishId}`);
};

const grantWish = (wishId: string, volunteer: Volunteer) => {
	return axios.put(`${baseURL}/grant-wish/${wishId}`, { volunteer });
}

const cancelGrant = (wishId: string) => {
	return axios.put(`${baseURL}/cancel-grant/${wishId}`);
}

const addWish = (details: FormData) => { // details: WishDetails) => {
	return axios.post(`${baseURL}/create-wish`, details);
}

const fetchRole = () => {
	return axios.get(`${baseURL}/check-role`);
}

const fetchUserAddresses = () => {
	return axios.get(`${baseURL}/get-addresses`);
}

const fetchUnverifiedWishes = () => {
	return axios.get(`${baseURL}/unverified-wishes`);
}

const fetchUnverifiedVolunteers = () => {
	return axios.get(`${baseURL}/unverified-volunteers`);
}

const validateWish = (wishId: string, isApproved: boolean, comment: string) => {
	return axios.put(`${baseURL}/verify-wish/${wishId}`, { approved: isApproved, comment: comment});
}

const validateVolunteer = (wishId: string, isApproved: boolean, comment: string) => {
	return axios.put(`${baseURL}/verify-volunteer/${wishId}`, { approved: isApproved, comment: comment});
}

export {
	fetchAllWishes,
	fetchUserWish,
	fetchWish,
	grantWish,
	addWish,
	fetchRole,
	fetchUserAddresses,
	fetchUnverifiedWishes,
	fetchUnverifiedVolunteers,
	validateWish,
	validateVolunteer,
	cancelGrant,
};