import React, {ReactNode, useEffect, useState} from 'react';
import './InputDialog.scss';
import { ReactComponent as CrossIcon } from '../assets/icons/cross.svg';
import SimpleBar from 'simplebar-react';

const InputDialog = ({ header, areaHeader, areaValue, onConfirm, onClose } :
                         {header?: string, areaHeader: string | ReactNode,
    areaValue: string, onConfirm:(value: string) => void, onClose: () => void}) => {
    const [textAreaValue, setTextAreaValue] = useState('');

    useEffect(() => {
        setTextAreaValue(areaValue)
    }, []);

  return (
      <div className="dialog-overlay">
          <div className="dialog-content">
              <div className="dialog-header">
                  {header}
              </div>
              <SimpleBar className="dialog-body">
                  {areaHeader}
              </SimpleBar>
              <textarea id="textArea" onChange={(event) => {
                  setTextAreaValue(event.target.value)}
              }>{areaValue}</textarea>
              <br/>
              <div className="footer-button-line">
                  <button className="footer-button" disabled={ textAreaValue == "" } onClick={() => onConfirm(textAreaValue)}>Подтвердить</button>
                  <button className="footer-button" onClick={onClose}>Отмена</button>
              </div>

              <button className="close-button" onClick={onClose}>
                  <CrossIcon/>
              </button>


          </div>
      </div>
  );
};

export default InputDialog;