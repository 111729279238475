import React, { useState } from 'react';
import './HeaderLogo.scss';
import logoImg from '../assets/img/logo.png' 
import { Link } from 'react-router-dom';

const HeaderLogo = () => {
  const [isGrowing, setIsGrowing] = useState(false);
  return (
    <div className="headerLogo">
      <Link to={`/?token=${localStorage.getItem('token')}`}>
        <span className="headerLogo-title">Ёлка <span className="headerLogo-title--yellow">Желаний</span></span>
      </Link>
      <div
        className={`headerLogo-logo ${isGrowing ? 'headerLogo-logo--growing' : ''}`}
      >
        <img
          style={{width: '100%'}}
          src={logoImg}
          alt="Logo"
          onClick={() => setIsGrowing(true)}
        />
      </div>
    </div>
  );
};

export default HeaderLogo;