import React, { ChangeEvent, Key, ReactEventHandler, useEffect, useState } from 'react';
import './NewWish.scss';
import {addWish, fetchUserAddresses, fetchUserWish} from '../api/api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Wish } from '../models/wish';
import Popup from './Popup';
import Header from './Header';
import Footer from './Footer';
import SimpleBar from 'simplebar-react';

const defaultErrors = {
	child: false,
	age: false,
	link: false,
	file: false,
	category: false,
	contact: false,
	gift: false,
	delivery: false,
};

const allowedExtensions = ['jpg', 'jpeg', 'pdf', 'png'];

function NewWish({ role }: {role: string}) 	{
	const [isChecked, setIsChecked] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const [popupContent, setPopupContent] = useState('');
	const [addresses, setAddresses] = useState([]);

	const [wish, setWish] = useState<Wish>();
	const [child, setChild] = useState('');
	const [age, setAge] = useState(0);
	const [gift, setGift] = useState('');
	const [link, setLink] = useState('');
	const [contact, setContact] = useState('');
	const [file, setFile] = useState<Blob | string>('');
	const [fileName, setFileName] = useState('');

	const [category, setCategory] = useState('Игрушки');
	const [delivery, setDelivery] = useState('');

	const [errors, setErrors] = useState(defaultErrors);
	const [isDisabled, setIsDisabled] = useState(false);

	const [volunteerName, setVolunteerName] = useState('');
	const navigate = useNavigate();
	useEffect(() => {
		console.log("ROLE", role);
		if (role === 'volunteer') {
		  // If the user has the role of 'volunteer', navigate them to a different route
		  navigate(`/?token=${localStorage.getItem('token')}`);
		}

	}, [role, wish]);


	useEffect(() => {
		getCurrentWish()
		fetchAddressRequest();
	}, []);

	useEffect(() => {
		setDelivery(addresses[0]);
	}, [addresses]);

	const getCurrentWish = async () => {
		try {
			const answ = await fetchUserWish();
			const wish = answ.data?.wish;
			if (wish) {
				setChild(wish.details?.child)
				setAge(wish.details?.age)
				setGift(wish.details?.gift)
				setLink(wish.details?.link)
				setFileName(wish.details?.imageUrl)
				if (!wish.checked || wish.approved) {
					onClose()
				}
			}
		} catch (error) {
			console.error('Error:', error);
		}
	}

	const fetchAddressRequest = async () => {
		try {
			const { data } = await fetchUserAddresses();
			console.log('fetchAddressRequest', data);
			setAddresses(data);
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const categoryOptions = [
		'Игрушки',
		'Книги',
		'Детская мебель',
		'Компьютер/ноутбук/планшет',
		'Музыкальные инструменты',
		'Новогодний декор',
		'Принадлежности для творчества',
		'Принадлежности для школы',
		'Спортивный инвентарь',
		'Телевизор',
		'Часы',
		'Фототехника',
		'Телефон/смартфон',
	];

	const onClose = () => {
		setShowPopup(false);
		navigate(`/?token=${localStorage.getItem('token')}`);
	}

	const validateWish = () => {
		setErrors(defaultErrors);
		const nameLength = child.split(' ').length;
		if (nameLength > 1) {
			return displayError('child', 'Имя должно состоять из одного слова');
		}
		/*const isTel = /^(?:\+7|8)\d{10}$/.test(contact);
		if (!isTel) {
			return displayError('contact', 'Неверный формат номера телефона');
		}*/
		if (!fileName) {
			return displayError('file', 'Прикрепите файл');
		}
		const extension = fileName.split('.').pop()?.toLowerCase();
		if (extension && !allowedExtensions.includes(extension)) {
			return displayError('file', 'Допустимые расширения .pdf, .jpeg, .jpg, .png');
		}
	};

	const displayError = (field: any, msg: string | undefined) => {
		setErrors({...errors,  [field]: msg });
		throw new Error(msg);
	}

	const submitNewWish = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!isChecked) {
			alert("Необходимо согласиться с условиями обработки персональных данных.");
			return;
		}
		try {
			validateWish();
			if (isDisabled) {
				return;
			}
			const formData = new FormData();
    		formData.append('image', file);
			formData.append('child', child);
			formData.append('age', age.toString());
			formData.append('link', link);
			formData.append('category', category);
			formData.append('gift', gift);
			formData.append('delivery', delivery);
			formData.append('contact', contact);
			setIsDisabled(true);
			const { data } = await addWish(formData);
			// const { data } = await addWish({
			// 	child,
			// 	age,
			// 	gift,
			// 	link,
			// 	category,
			// 	delivery,
			// 	contact
			// });
			console.log(data);
			setPopupContent('Отправлено');
			setShowPopup(true);
			e.preventDefault();
			setIsDisabled(false);
		} catch (error) {
			console.error('Error:', error);
			setIsDisabled(false);
		}
	};

	const handleChildChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
		setChild(e.target.value);
		setErrors({...errors, child: false});
		const nameLength = e.target.value.toString().split(' ').length;
		if (nameLength > 1) {
			return displayError('child', 'Имя должно состоять из одного слова');
		}
	};

	const handleAgeChange = (e: ChangeEvent<HTMLInputElement>) => {
		console.log('age', parseInt(e.target.value));
		setAge(parseInt(e.target.value));
	};

	const handleGiftChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
		setGift(e.target.value);
	};

	const handleCategoryChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
		setCategory(e.target.value);
	};
	  
	const handleDeliveryChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
		setDelivery(e.target.value);
	};

	const handleLinkChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
		setLink(e.target.value);
	};
	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsChecked(e.target.checked);
	};

	const handleContactChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
		setContact(e.target.value);
		setErrors({...errors, contact: false});
		const isTel = /^(?:\+7|8)\d{10}$/.test(e.target.value.toString());
		if (!isTel) {
			return displayError('contact', 'Неверный формат номера телефона');
		}
	}

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFile(e.target.files![0]);
		setFileName(e.target.files![0].name);

		const extension = e.target.files![0].name.split('.').pop()?.toLowerCase();
		console.log('FILE', e.target.files![0]);
		setErrors({...errors, file: false});
		if (!e.target.files![0]) {
			return displayError('file', 'Прикрепите файл');
		}
		if (extension && !allowedExtensions.includes(extension)) {
			return displayError('file', 'Допустимые расширения .pdf, .jpeg, .jpg, .png');
		}
	};
 
	return (
		<>
			<Header/>
			<div className="newWish">
				<SimpleBar className="newWish-scroll">
					<form encType="multipart/form-data" className="newWish-form" onSubmit={submitNewWish}>
						<label className={errors['child'] ? 'hasError' : ''}>
							Имя ребёнка
							<input name="child" type="text" value={child} onChange={handleChildChange} required />
							<div className="errorMsg">{errors['child']}</div>
						</label>
						<label className={errors['age'] ? 'hasError' : ''}>
							Возраст ребёнка
							<input name="age" type="number" value={age} onChange={handleAgeChange} min={0} required />
							<div className="errorMsg">{errors['age']}</div>
						</label>
						<label className={errors['link'] ? 'hasError' : ''}>
							Ссылка на пример подарка из онлайн-магазина
							<input name="link" type="text" value={link} onChange={handleLinkChange} required/>
							<div className="errorMsg">{errors['link']}</div>
						</label>
						<label className={`fileLabel ${errors['file'] ? 'hasError' : ''}`}>
							Письмо ребенка (pdf, jpeg, jpg, png)
							<input
								name="link"
								type="file"
								accept=".pdf, .jpeg, .jpg, .png"
								onChange={handleFileChange}
								hidden
							/>
							<div className="inputFile">{fileName ? fileName : 'Выберите файл'}</div>
							<div className="errorMsg">{errors['file']}</div>
						</label>
						{/*<label className={errors['category'] ? 'hasError' : ''}>
							Категория подарка
							<select value={category} onChange={handleCategoryChange} name="category">
								{
									categoryOptions.map(option => (
										<option
											key={option}
											value={option}
										>{option}
										</option>
									))
								}
							</select>
							<div className="errorMsg">{errors['category']}</div>
							//<input type="text" value={category} onChange={handleCategoryChange} />
						</label>*/}
						{/*<label className={errors['contact'] ? 'hasError' : ''}>
							Номер телефона
							<input name="contact" type="text" value={contact} onChange={handleContactChange} />
							<div className="errorMsg">{errors['contact']}</div>
						</label>*/}
						<label className={errors['gift'] ? 'hasError' : ''}>
							Краткое описание подарка
							<input name="gift" type="text" value={gift} onChange={handleGiftChange} required/>
							<div className="errorMsg">{errors['gift']}</div>
						</label>
						{/*<label className={errors['delivery'] ? 'hasError' : ''}>
							Место доставки
							<select value={delivery} onChange={handleDeliveryChange} name="delivery" >
								{
									addresses.map(option => (
										<option key={option} value={option}>{option}</option>
									))
								}
							</select>
							<div className="errorMsg">{errors['delivery']}</div>
						</label>*/}
						<div className="agreement-section">
							<label htmlFor="agreement">
								<input
									className='agreement-checkbox'
									id="agreement"
									type="checkbox"
									checked={isChecked}
									onChange={handleCheckboxChange}
								></input>
								<span>
									Нажимая на кнопку «Начать заполнение анкеты», я &nbsp;
									<a
										className="agreement-link"
										href="/agreement_parents.pdf"
										target="_blank"
									>даю согласие на обработку персональных данных &nbsp;
									</a>
									в соответствии с законодательством Российской Федерации и &nbsp;
									<a
										className="agreement-link"
										href="/personalDataPolicy.pdf"
										target="_blank"
									>«Политикой обработки персональных данных ПАО «Интер РАО».
									</a>
								</span>
							</label>
						</div>
						<button disabled={isDisabled}>Отправить заявку</button>
					</form>
				</SimpleBar>
			</div>
			<Footer/>
			{showPopup && (
				<Popup content={popupContent} onClose={onClose} />
			)}
		</>
	);
}

export default NewWish;
