import BallRed1 from '../assets/img/ball-red-1.png';
import BallRed2 from '../assets/img/ball-red-2.png';
import BallRed3 from '../assets/img/ball-red-3.png';
import BallGreen1 from '../assets/img/ball-green-1.png';
import BallGreen2 from '../assets/img/ball-green-2.png';
import BallGreen3 from '../assets/img/ball-green-3.png';
import BallGreen4 from '../assets/img/ball-green-4.png';
import BallGreen5 from '../assets/img/ball-green-5.png';
import BallGreen6 from '../assets/img/ball-green-6.png';

const RED_BALLS= [BallGreen1, BallGreen2, BallGreen3, BallGreen4, BallGreen5, BallGreen6];
const GOLD_BALLS = [BallRed1, BallRed2, BallRed3];

export default function TreeBall(isFulfilled: boolean | undefined, order: number) {
    // console.log('isFulfilled', isFulfilled);
    // console.log('order', order);
    if (isFulfilled) {
        return GOLD_BALLS[order % GOLD_BALLS.length];
    }
    return RED_BALLS[order % RED_BALLS.length];
}