import React from 'react';
import './Rules.scss';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Accordion from './Accordion';
import './WishList.scss';
function RulesWishmaker() {
	return (
		<div className='rules'>
			<Header/>
			<div className='rules-content'>
				<h1>Общие правила участия в акции</h1>
				<br/>
				<p>
					1. Возраст детей: от 0 до 18 лет (включительно).
					<br/><br/>
					2. Дети готовят письма с желаниями, в которых могут рассказать о себе, своих увлечениях, успехах в учёбе, спорте или творчестве, а также сделать рисунки или поделки своим дарителям.
					<br/><br/>
					3. Письма с желаниями и рисунки (фотографии поделок) должны быть в формате JPEG.
					<br/><br/>
					4. Письма не должны содержать персональных данных детей и родителей. Допускается написание имени (без указания фамилии) и возраста ребёнка.
					<br/><br/>
					5. Родители получают ссылку на сайт «Ёлки желаний», где им потребуется до 30 октября 2024 заполнить карточку желания, а также загрузить фотографии/сканы детских писем и рисунков.
					<br/><br/>
					6. Подарки должны быть доступны на маркетплейсах. Запрещено размещать ссылки на социальные сети.

				</p>
				<p>	<u><b>Какие желания могут быть исполнены?</b></u></p>
				<p>Материальные желания:
					<ul>
						<li>Одежда</li>
						<li>Игрушки</li>
						<li>Спортивный инвентарь и экипировка</li>
						<li>Товары для хобби</li>
						<li>Развивающие материалы, книги.</li>
						<li>Полупрофессиональные музыкальные инструменты.</li>
					</ul>
				</p>
				<p><u><b>Какие желания нельзя загадать?</b></u></p>
				<p>У акции «Ёлка желаний» есть свои ограничения. В ней нельзя загадать:
					<ul>
						<li>Цифровую технику</li>
						<li>Прохождение лечения и предоставление медицинских услуг</li>
						<li>Приобретение технических средств реабилитации и абилитации</li>
						<li>Приобретение специализированного медицинского оборудования</li>
						<li>Приобретение недвижимости и транспортных средств</li>
						<li>Приобретение животных</li>
						<li>Ремонт помещений</li>
						<li>Нематериальные желания</li>
						<li>Сертификаты на покупку товаров и услуг и/или денежные средства.</li>
					</ul>
				</p>

				<div style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',

				}}>
					<Link className='wishList-button' to={`/?token=${localStorage.getItem('token')}`}>
						<button>Принимаю!</button>
					</Link>
				</div>


			</div>
			
			<Footer/>
		</div>
	);
}

export default RulesWishmaker;
