import React from 'react';
import './Footer.scss';
import logoImg from '../assets/img/logo.png' 
import Ball from './Ball';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-balls">
        {[...Array(12)].map((_, index) => (
          <div className='footer-ball'>
            <Ball
              key={index}
              index={index}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Footer;