import React from 'react';
import './Rules.scss';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Accordion from './Accordion';
import './WishList.scss';
function Rules() {
	return (
		<div className='rules'>
			<Header/>
			<div className='rules-content'>
				<h1>Общие правила участия в акции</h1>
				<br/>
				<p>
					Чтобы акция принесла счастье и дарителям, и получателям подарков, просим вас соблюдать несколько простых правил:
					<br/><br/>
					1. Выберите одно или несколько <a className='highlight' href={`/?token=${localStorage.getItem('token')}`}>желаний</a>, которые вам хотелось бы исполнить. Для этого просматривайте информацию на интерактивных ёлочных шарах.
					<br/><br/>
					2. Вы можете выбрать вариант отображения подарков – в виде шариков на ёлке или в виде списка. Для этого воспользуйтесь кнопкой в правом верхнем углу сайта.
					<br/><br/>
					3. Зелёный цвет шарика означает, что желание ещё не выбрано исполнителем. Красный – что кто-то уже его забронировал. Дважды выбрать один и тот же шарик не получится благодаря настройкам сайта.
					<br/><br/>
					4. Все приобретённые вещи должны быть новыми, чистыми, в цельной и прочной упаковке для пересылки в Общества Группы «Интер РАО». Приветствуется праздничная упаковка.
					<br/><br/>
					5. Подарки следует заказывать напрямую в офисы Общества Группы «Интер РАО». Для этого на сайт акции уже загружены почтовые адреса офисов, в которых коллеги примут отправленные вами подарки и вручат подопечным. При оформлении заказа, пожалуйста, убедитесь, что выбранный подарок доставляется по адресу Общества. Почтовый адрес и другие необходимые контакты для отправки отображаются в описании каждого желания.
					<br/><br/>
					6. Сделайте отметку-комментарий при отправке подарков курьерской службой маркетплейса с именем и возрастом ребенка – это поможет коллегам, которые будут получать подарки в офисах Обществ Группы «Интер РАО» и организовывать вручение, разобраться, кому они предназначаются.
					<br/><br/>
					7. Любой подарок можно приобрести коллективно вместе со своими коллегами.
					<br/><br/>
					8. После того как вы выбрали желание, не забудьте заказать и отправить выбранный вами подарок. Мы напомним вам об этом в уведомлении – ищите его в почте, указанной при выборе шарика!
					<br/><br/>
					Помогите сделать сайт <a className='highlight' href={`/?token=${localStorage.getItem('token')}`}>«Ёлка желаний»</a> лучше – по вопросам и предложениям, связанным с использованием и развитием сайта, пишите на эл. почту <a className='highlight' href="mailto:elka@interrao.ru">elka@interrao.ru</a>
					<br/><br/>
					Благодарим за ваши добрые сердца, волшебники «Интер РАО»!
					
				</p>
				
				<div style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					
				}}>
					<Link className='wishList-button' to={`/?token=${localStorage.getItem('token')}`}>
						<button>Принимаю!</button>
					</Link>
				</div>
				

			</div>
			
			<Footer/>
		</div>
	);
}

export default Rules;
