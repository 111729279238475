import { useState } from "react";
import TreeBall from "./TreeBall";
import './Ball.scss';

export default function Ball({index}: {index: number}) {
    const [isShaking, setIsShaking] = useState(false);

    const onClick = (shake: boolean) => {
        setIsShaking(shake);
        if (shake) {
            const bellAudio = new Audio('/bell.wav');
            bellAudio.play();
        }
    }

    return (
        <img
            src={TreeBall(index % 2 === 0, index % 6)}
            className={`ball ${isShaking ? 'ball--shaking' : ''}`}
            alt='Christmas Ball'
            onClick={() => onClick(!isShaking)}
        />
    )
}