import React, { Key, useEffect, useState } from 'react';
import './App.css';
import WishList from './components/WishList';
import Wish from './components/WishDetails';
import { Route, Routes, useLocation } from "react-router";
import NewWish from './components/NewWish';
import ValidateWish from './components/ValidateWish';
import Rules from './components/Rules';
import RulesWishmaker from "./components/RulesWishmaker";
import ValidateVolunteer from './components/ValidateVolunteer';
import { fetchRole } from './api/api';

const App = () => {
  	const [role, setRole] = useState('');
	const urlParams = new URLSearchParams(window.location.search);
	const token = urlParams.get('token');
	console.log('TOKEN1 is', token);
	if (token) {
		localStorage.setItem('token', token);
	}
	console.log('token1', token);

  	const fetchRoleRequest = async () => {
		if (token) {
			try {
				const { data } = await fetchRole();
				console.log('fetchRoleRequest', data);
				setRole(data);
			} catch (error) {
				console.error('Error:', error);
				localStorage.setItem('token', '');
			}
		}
	};

	const location = useLocation();

  	useEffect(() => {
		const handleRouteChange = () => {
			const currentPath = location.pathname;

			if (!localStorage.getItem('token')) {
				return document.body.style.backgroundImage = 'none';
			}
			if (currentPath === '/') {
				document.body.removeAttribute('style');
			} else {
				document.body.style.backgroundImage = 'linear-gradient(to bottom, #00042D 0%, #022F6E 100%)';
			}
		};
		handleRouteChange();

		return () => {};
	}, [location]);

  	useEffect(() => {
		fetchRoleRequest();
	}, []);

	return (
		(localStorage.getItem('token') && role) ? (
			<div className="App">
				<Routes>
					<Route path="/" element={<WishList role={role} />} />
					<Route path="/wish/:wishId" element={<Wish role={role}/>} />
					<Route path="/new-wish" element={<NewWish role={role}/>} />
					<Route path="/validate-wish/:wishId" element={<ValidateWish role={role}/>} />
					<Route path="/validate-volunteer/:wishId" element={<ValidateVolunteer role={role}/>} />
					<Route path="/rules" element={<Rules />} />
					<Route path="/rules-wishmaker" element={<RulesWishmaker />} />
				</Routes>
			</div>
		) : <div className="App App--empty">Пожалуйста, войдите по ссылке</div>
	);
};

export default App;
